.wrapper {
  width: 100%;
  @include ltr-rtl('padding-left', var(--cui-sidebar-occupy-start, 0));
  will-change: auto;
  @include transition(padding 0.15s);
}

.left-hand-menu {
  background-color: $menu-bg-hover;
}

.left_hand_menu-item {
  padding-left: 10px;
}

.left_hand_menu-item .btn-link {
  color: #fff;
  font-size: 13px;
  font-weight: 500;
  text-align: left;
  width: 100%;
  padding: 10px 15px;
  text-decoration: none;
}

.left-hand-menu .dropdown {
  width: 100%;
}

.left-hand-menu .dropdown-toggle {
  width: 10px;
}

td.long-text-col {
  overflow-x: scroll;
  white-space: nowrap;
  max-width: 220px;
}
.icon-iframe {
  position: fixed;
  bottom: 20px;  /* Distance from the bottom edge of the viewport */
  right: 20px;   /* Distance from the right edge of the viewport */
  z-index: 1000; /* Ensure it appears above other content */
  transition: width 0.5s ease, height 0.5s ease;
}