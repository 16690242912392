/* +++++++++++ GENERAL ++++++++++ */
body {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  background-color: #ebedef;
  font-size: 0.875rem;
}

.c-app {
  display: flex;
  flex-direction: row;
  min-height: 100vh;
}

.left-right {
  display: flex;
  justify-content: space-between;
}

a {
  text-decoration: none;
}

h6,
.h6 {
  font-size: 0.75rem;
}

.text-muted-less {
  color: var(--cui-muted, rgba(44, 56, 74, 0.65)) !important;
}

.popover-body {
  padding: 0.5rem 0.5rem;
}

.wide-pop {
  max-width: 400px;
}

.tools-icon:focus {
  box-shadow: unset;
  background-color: unset;
}

.tools-icon:hover {
  background-color: #eee;
}

.dropdown-item svg {
  margin-right: 5px;
}

.dropdown-header {
  margin-bottom: 5px;
}
.dropdown-divider {
  margin-bottom: 0;
  margin-top: 0;
  border-top: 1px solid #999;
}

.loader-backdrop {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 998;
  background-color: rgb(0, 0, 0, 0.1);
}

.spinner-border {
  top: calc(50% - 58px);
  right: calc(50% - 58px);
  display: block;
  position: absolute;
  z-index: 999;
  color: $primary;
}

.spinner-border-inline {
  position: unset;
  margin-top: 10px;
}

/* +++++++++++ FORMS ++++++++++ */
.stretch-input {
  flex: 1 1 auto;
}

.form-control {
  font-size: 0.875rem;
}

.input-group-text {
  background-color: #ebedef;
  border-color: #d8dbe0;
  color: #768192;
  font-size: 0.875rem;
}

.form-error-alert {
  color: $danger;
  font-weight: 600;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.phone-invalid .PhoneInputInput {
  @extend .form-control, .is-invalid;
}
.PhoneInputInput {
  @extend .form-control;
  border-radius: 0 0.25rem 0.25rem 0;
}

.PhoneCountry {
  border: 1px solid var(--cui-input-border-color, #b1b7c1);
  padding: 7px;
  margin-right: 0;
  border-right: none;
  border-radius: 0.25rem 0 0 0.25rem;
}

.select-header {
  font-weight: 600;
  margin: 0 0 0 10px;
  position: fixed;
  z-index: 1;
  font-size: 11px;
  opacity: 0.5;
}

.filter-block {
  min-width: 160px;
  max-width: 210px;
}

.text-filter {
  background-color: hsl(0, 0%, 100%);
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  padding: 15px 0 6px 10px;
}

.text-filter:focus {
  outline-color: #2684ff;
  border-color: #2684ff;
}
.text-filter:focus-visible {
  outline-color: #2684ff;
  border-color: #2684ff;
}

.sliding-panel-container .panel-content {
  height: 100%;
  width: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.sliding-panel-container .panel-content .cancel {
  position: absolute;
  right: 15px;
  top: 10px;
}

button .spinner-border {
  color: #fff;
  position: unset;
  margin-top: 2px;
}

.drag-area {
  font-size: 1.25rem;
  background-color: #f8f8ff;
  position: relative;
  padding: 30px 20px;
  outline: 2px dashed #92b0b3;
  outline-offset: -10px;
}

.drag-area-icon {
  width: 100%;
  height: 80px;
  fill: #92b0b3;
  display: block;
  margin-bottom: 40px;
}

// SIDEBAR

.sidebar {
  width: 13.5rem;
}

.sidebar-header {
  flex: 0 0 4rem;
  padding: 0.5rem 1rem 1rem 1rem;
  background: #060f76;
  text-align: unset;
  transition: height 0.15s, padding 0.15s;
  visibility: unset !important;
  opacity: unset !important;
}

.ps-collapsed {
  min-width: 75px !important;
  width: 75px !important;
}

.ps-menu-icon {
  margin-right: 5px !important;
}

.ps-sidebar-root:not(.ps-collapsed) .ps-menu-button {
  padding-left: 10px !important;
}

.ps-menu-button.ps-open {
  background-color: $menu-bg-hover !important;
}

.ps-menuitem-root.ps-open .ps-menu-button {
  opacity: 0.97;
  background-color: $menu-bg-hover !important;
}

.ps-menuitem-root.ps-open-active .ps-menu-button {
  font-weight: 600;
  opacity: 1;
  text-decoration: underline;
}

.ps-menu-button:hover {
  background-color: $menu-bg-hover !important;
}

.ps-submenu-content {
  background-color: $menu-bg !important;
  border-top: #060f76 solid 1px;
  padding-left: 5px;
}

.ps-collapsed .ps-submenu-expand-icon {
  display: none;
}

.sidebar-header img {
  vertical-align: bottom;
}

.sidebar-narrow-unfoldable .sidebar-header {
  padding: 0.8rem 0.7rem !important;
}

.sidebar-narrow-unfoldable .sidebar-header img {
  height: 40px;
}

.sidebar-header span {
  font-size: 17px;
  padding: 0 0 0 13px;
  border-left: 3px solid white;
  margin-left: 5px;
  display: inline-block;
  max-width: 100px;
}

.sidebar-title {
  text-transform: uppercase;
  font-size: 11.2px !important;
  font-weight: 600;
}

.ps-collapsed .sidebar-title {
  visibility: hidden;
}

.sidebar-title a:hover {
  background-color: var(
    --cui-sidebar-header-bg,
    rgba(0, 0, 21, 0.2)
  ) !important;
  color: unset !important;
  cursor: default;
}

.sidebar-title span {
  font-weight: 600;
}

.sidebar-nav {
  font-size: 14px;
}

.sidebar-nav .nav-link {
  padding: 0.75rem 1rem;
}

.sidebar-nav .nav-icon {
  flex: 0 0 3rem;
}

.sidebar-narrow-unfoldable .sidebar-nav .nav-icon {
  flex: 0 0 4rem;
}

.sidebar:not(.sidebar-end) ~ * {
  --cui-sidebar-occupy-start: 13.6rem;
}

.sidebar-narrow-unfoldable.sidebar:not(.sidebar-end) ~ * {
  --cui-sidebar-occupy-start: 5rem;
}

.nav-icon.svg-inline--fa {
  height: 1rem;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #999;
}

/* +++++++++++ HEADER ++++++++++ */
.c-avatar {
  font-size: 35px;
}

.header {
  padding-bottom: unset;
  padding-top: unset;
  min-height: unset;
}

/* +++++++++++ PROFILE ++++++++++ */
.profile-view {
  position: relative;
}

.profile-img-wrap {
  height: 120px;
  position: absolute;
  width: 120px;
  background: #fff;
  overflow: hidden;
}

.profile-img {
  cursor: pointer;
  height: 80px;
  margin: 0 auto;
  position: relative;
  width: 80px;
}

.profile-view .profile-basic {
  margin-left: 140px;
  padding-right: 50px;
}

.profile-info-left {
  border-right: 2px dashed #ccc;
  min-height: 105px;
  margin-right: 10px;
  padding-right: 25px;
}

.profile-left-title {
  font-weight: 600;
}

.profile-card {
  margin-top: 20px;
}

.profile-list {
  padding-top: 10px;
}

.profile-list .row {
  padding-top: 15px;
}

.profile-list .col:first-of-type {
  font-weight: 600;
}

.profile-card .card-header {
  font-size: 18px;
  font-weight: 500;
  border-bottom: unset;
  background-color: unset;
}

.profile-card .card-body {
  padding-top: unset;
}

.toggle-switch input {
  height: 20px;
  width: 40px !important;
  margin-right: 15px;
}

.toggle-switch label {
  padding-top: 4px;
}

.user-name {
  color: #333;
}

.m-t-0 {
  margin-top: 0 !important;
}
.personal-info {
  list-style: none;
  margin-bottom: 0;
  padding: 0;
}

.personal-info li {
  margin-bottom: 10px;
}

.personal-info li .title {
  color: #4f4f4f;
  float: left;
  font-weight: 500;
  margin-right: 30px;
  width: 20%;
}

.personal-info li .text {
  color: #8e8e8e;
  display: block;
  overflow: hidden;
}

.profile-view .pro-edit {
  position: absolute;
  right: 0;
  top: 0;
}

.page-tools button {
  padding: 5px 13px;
}

.tools-icon {
  border-radius: 20px;
  font-size: 12px;
  margin-right: 5px;
  padding: 5px 8px;
}

.tools-icon:hover {
  background-color: #eee;
}

.row-actions .tools-icon {
  padding: 3px 10px;
}

/* ++++++++++++++++ Nav & Tabs +++++++++++++++++++++ */
.g-tabs {
  border-bottom: 1px solid #dee2e6;
  margin-top: 2px;
  background-color: #fff;
}

.g-tabs .nav-link {
  border-radius: unset;
  color: var(--cui-muted, rgba(44, 56, 74, 0.65));
}

.g-tabs .nav-link.active {
  border-bottom-width: 2px;
  border-color: transparent;
  border-bottom-color: $primary;
  color: #333;
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.node-active {
  animation: blink 1s 3;
}

/* ++++++++++++++++ Table +++++++++++++++++++++ */
.g-table {
  width: 100%;
  text-align: left;
  border-radius: 2px 2px 0 0;
  border-collapse: separate;
  border-spacing: 0;
}

.g-table tr {
  box-shadow: 0 0 3px #e5e5e5;
}

.g-table th {
  padding: 9px 20px 9px 8px !important;
}

.g-table td {
  padding: 9px 8px !important;
}

.g-table th.center-align-col,
.g-table td.center-align-col {
  text-align: center;
}

.g-table th.br-type-col,
.g-table td.br-type-col {
  width: 100px;
}

.g-table th:first-child,
.g-table td:first-child {
  padding-left: 15px !important;
}

.g-table td {
  font-size: 12px;
}

td.long-text {
  max-width: 130px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.g-table th.default {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATCAQAAADYWf5HAAAAkElEQVQoz7XQMQ5AQBCF4dWQSJxC5wwax1Cq1e7BAdxD5SL+Tq/QCM1oNiJidwox0355mXnG/DrEtIQ6azioNZQxI0ykPhTQIwhCR+BmBYtlK7kLJYwWCcJA9M4qdrZrd8pPjZWPtOqdRQy320YSV17OatFC4euts6z39GYMKRPCTKY9UnPQ6P+GtMRfGtPnBCiqhAeJPmkqAAAAAElFTkSuQmCC);
}

.g-table th.up {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATCAYAAAByUDbMAAAAZ0lEQVQ4y2NgGLKgquEuFxBPAGI2ahhWCsS/gDibUoO0gPgxEP8H4ttArEyuQYxAPBdqEAxPBImTY5gjEL9DM+wTENuQahAvEO9DMwiGdwAxOymGJQLxTyD+jgWDxCMZRsEoGAVoAADeemwtPcZI2wAAAABJRU5ErkJggg==);
}

.g-table th.down {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATCAYAAAByUDbMAAAAZUlEQVQ4y2NgGAWjYBSggaqGu5FA/BOIv2PBIPFEUgxjB+IdQPwfC94HxLykus4GiD+hGfQOiB3J8SojEE9EM2wuSJzcsFMG4ttQgx4DsRalkZENxL+AuJQaMcsGxBOAmGvopk8AVz1sLZgg0bsAAAAASUVORK5CYII=);
}

.g-table th.up,
.g-table th.default,
.g-table th.down {
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: center right;
}

.g-table .tools-icon {
  border: 0;
}

.g-table .row-actions {
  width: 40px;
}

.g-table .loc-col {
  max-width: 200px;
  overflow: overlay;
}

td::-webkit-scrollbar {
  height: 8px;
}

.g-table .row-actions .tools-icon {
  font-size: 14px;
  background-color: unset;
}

.table-container {
  width: 100% !important;
  overflow-x: scroll;
  padding-bottom: 30px;
}

.table-container-no-scroll {
  width: 100% !important;
  padding-bottom: 30px;
}

table {
  min-width: -moz-fit-content;
  min-width: fit-content;
  margin: 0 auto;
  overflow-x: auto;
  // white-space: nowrap;
}

/* ++++++++++++++++ Modal +++++++++++++++++++++ */
.modal-header {
  padding: 10px 16px;
}

/* ++++++++++++++++ Paginator +++++++++++++++++++++ */
.g-pagination-container {
  padding-top: 30px;
}

.g-pagination-container button {
  background-color: white;
  color: $primary;
}

.g-pagination-container button:disabled {
  background-color: white;
  color: #8a93a2;
}

.g-pagination-container button.active {
  color: white;
  background-color: $primary;
}

.g-pagination-text {
  display: flex;
  align-items: center;
  justify-content: center;
}

.dropdown-item {
  cursor: pointer;
}

.pointer {
  cursor: pointer;
}

.select-header {
  position: absolute;
  padding-top: 2px;
}

.chart-card {
  margin-top: 20px;
}

.chart-card .card-header {
  font-size: 18px;
  font-weight: 500;
  border-bottom: unset;
  background-color: unset;
}

.chart-card .card-body {
  padding-top: unset;
}

.chart-table-container {
  padding-top: 15px;
  font-size: 12px;
}

.chart-table-head {
  background-color: #e5e5e5;
}

.modal-wide {
  width: 60%;
  max-width: none;
}

/* ++++++++++++++++ Stats Block +++++++++++++++++++++ */
.stats-box {
  background-color: #f9f9f9;
  border: 1px solid #e3e3e3;
  margin-bottom: 15px;
  padding: 5px;
}

.stats-box div {
  font-size: 12px;
}

.stats-block {
  min-height: 300px;
}

.stats-block .progress {
  height: 12px;
  margin-left: 22px;
  margin-top: 2px;
}

.stats-block .progress-bar {
  font-size: 9px;
}

/* ++++++++++++++++ Stepper +++++++++++++++++++++ */

.stepper:after {
  content: '';
  border: 3px dashed #e4e6ef;
  position: absolute;
  top: 30px;
  bottom: 170px;
  left: 40px;
  z-index: 0;
  border-right: unset;
}

.stepper-item {
  display: flex;
  align-items: center;
  padding: 1rem 0 3rem 0.5rem;
  margin: 0;
}

.stepper-form {
  min-height: 330px;
}

.stepper-item.current .stepper-icon {
  background-color: #00b2ff;
  color: #fff;
}

.stepper-item.completed .stepper-icon {
  background-color: $success;
  color: #fff;
}

.stepper-icon {
  transition: color 0.2s ease, background-color 0.2s ease;
  background-color: #f1faff;
  color: #00b2ff;
  font-size: 1.35rem;
  font-weight: 600;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.475rem;
  margin-right: 1rem;
  z-index: 1;
}

.stepper-icon svg {
  display: none;
}

.stepper-item.completed .stepper-icon svg {
  display: block;
}

.stepper-item.completed .stepper-icon .stepper-number {
  display: none;
}

.stepper-title {
  color: #7e8299;
  font-weight: 600;
  font-size: 1rem;
  margin-bottom: 0.3rem;
}

.stepper-desc {
  color: #b5b5c3;
}

/* ++++++++++++++++ Card +++++++++++++++++++++ */
.overview-card .card-body div {
  padding-top: 3px;
}

.overview-card .card-body .btn-link {
  padding: unset;
}

.power-bi-report {
  height: 82vh;
}

.card-title {
  font-size: 20px;
}

// HEADER
#header-nav {
  margin-bottom: 10px;
}

#header-nav .dropdown-toggle::after {
  display: none;
}

#header-nav .dropdown-toggle {
  padding-top: 0;
  padding-bottom: 0;
}

.dropdown-menu,
.dropdown-item {
  color: $menu-text-color;
  background-color: $menu-bg;
}

.dropdown-item:hover {
  color: $menu-text-color;
  background-color: $menu-bg-hover !important;
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: $menu-bg-hover !important;
}
